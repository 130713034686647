
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import type CompsetsService from '@/modules/compsets/compsets.service';
import type ProvidersService from '@/modules/providers/providers.service';
import type UserService from '@/modules/user/user.service';
import type MarketsHistoryService from '../markets-history.service';

@Component
export default class RatesPriceHistoryFilters extends Vue {
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;
    @inject(KEY.MarketsHistoryService) private marketsHistoryService!: MarketsHistoryService;
    @inject(KEY.CompsetsService) private compsetsService!: CompsetsService;
    @inject(KEY.ProvidersService) private providersService!: ProvidersService;
    @inject(KEY.UserService) private userService!: UserService;

    get compsetId() {
        return this.$route.params.compsetId
            || this.documentFiltersService.settings.compsetId;
    }

    get compSet() {
        if (!this.compsetsService.compsets) {
            return '';
        }

        const currentCompset = this.compsetsService.getCompset(this.compsetId);
        const type = this.$tc(`compset.${currentCompset?.type}`);

        return currentCompset
            ? `${currentCompset.name} (${type})`
            : '';
    }

    get source() {
        if (this.userService.isViewAsCluster || this.userService.isViewAsChain) {
            return this.marketsHistoryService.marketsDocument?.settings.provider;
        }

        return this.$route.params.source;
    }

    get los() {
        if (this.userService.isViewAsCluster || this.userService.isViewAsChain) {
            return this.marketsHistoryService.marketsDocument?.settings.los;
        }

        return this.documentFiltersService.settings.los || 1;
    }

    get pos() {
        if (this.userService.isViewAsCluster || this.userService.isViewAsChain) {
            return this.marketsHistoryService.marketsDocument?.settings.pos;
        }

        return this.documentFiltersService.settings.pos || '';
    }

    getProviderLabel(providerName: string) {
        const { allProvidersCollection } = this.providersService;

        if (!allProvidersCollection) {
            return providerName;
        }

        const provider = allProvidersCollection.find(currentProvider => currentProvider.name === providerName);

        if (!provider) {
            return '';
        }

        return provider.label || provider.name || '';
    }
}
